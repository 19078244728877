.container {
  display: flex;
  padding-top: 1rem;

  div[class*='checkboxLegendWrapper'] {
    display: flex;
    align-items: center;
  }

  span[class*='checkmarkCustom'] {
    margin-right: 0.4rem;
  }
}

.container {
  label:first-child {
    div[class*='checkboxLegendWrapper'] {
      margin-right: 2.5rem;
    }
  }
}

.checkRadio {
  align-items: center;
  margin-bottom: 0;
}

.unchecked {
  opacity: 0.6;
}

.icon {
  width: 0.5rem;
  height: 0.5rem;
  color: var(--color-blue-dark);
}

.text {
  font-size: 1.4rem;
  color: var(--color-gray-700);
}
