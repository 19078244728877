.copyright {
  color: var(--color-white);
  font-size: 1.2rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  color: #787e8c;
  @media (min-width: 64rem) {
    color: #787e8c;
  }
  svg {
    margin-bottom: 1.2rem;
  }
}

.copyrightCustomLogin {
  color: #787e8c;
}

.links {
  span {
    padding: 0px 0.6rem;
  }
  a {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
