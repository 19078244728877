.gridSelectorContainer {
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
}

.icon {
  width: 3.2rem;
  height: 3.2rem;

  &:first-child {
    margin-right: 0.5rem;
  }
}

.iconRows {
  font-size: 2.2rem;
}
