.emailPreviewWrapper {
  width: 100%;
}

.emailPreviewFrame {
  .emailPreview {
    max-width: 80rem;
    border: 0.1rem dashed #979797;
    border-radius: 0.5rem;
  }
}

.emailPreview {
  padding: 2.5rem 2rem;
  margin: auto;
  background: white;
}

.companyLogoContainer {
  padding: 1rem 2.5rem;
}
.companyLogo {
  width: 30rem;
  margin: auto;
}

.title {
  padding: 2rem;
  margin: auto;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  color: #808080;
}

.sectionContent {
  padding: 1rem 0;
  font-size: 1.3rem;
  font-weight: 300;

  p {
    color: black;
    margin: 1.3rem 0;
  }

  img {
    padding: 1rem 0;
  }
}

.droppableSection {
  height: 5rem;
  background: gray;
}

.dropContainer {
  min-height: 5rem;
}

.draggingOver {
  background: var(--color-gray-100);
}
