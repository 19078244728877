.checkboxContainer {
  color: #4a4a68;
  cursor: pointer;
  display: block;
  padding-left: 2.5rem;
  position: relative;
  user-select: none;
  font-size: 1.4rem;

  input {
    width: 0;
  }
  &:focus-visible {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 2px solid var(--color-blue-primary);
    border-radius: 0.4rem;

    span[class*='checkmarkDefault'] {
      margin-top: 0.5rem;
    }
  }

  & > span:nth-child(1) {
    display: inline-block;
  }

  > input:checked ~ .checkmarkDefault {
    background-color: var(--color-blue-primary);
    border-color: var(--color-blue-primary);

    &:after {
      display: block;
    }
  }

  > input:checked ~ .checkmarkIndeterminate {
    background-color: var(--color-white);
    border-color: var(--color-blue-primary);

    &:after {
      display: block;
      background-color: var(--color-blue-primary);
    }
  }

  > input:disabled ~ .checkmarkDefault {
    cursor: not-allowed;
    opacity: 0.5;

    &:after {
      display: none;
    }
  }

  > input:checked ~ .checkmarkDefault {
    &:after {
      display: block;
    }
  }

  &:after {
    border-color: var(--color-white);
    border-width: 0 0.2rem 0.2rem 0;
    height: 0.8rem;
    left: 0.5rem;
    top: 0.1rem;
    transform: rotate(45deg);
    width: 0.4rem;
  }
}

.checkboxInput {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.checkmarkDefault {
  border-radius: 0.3rem;
  border: 0.1rem solid #bbbfc5;
  height: 1.6rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 1.6rem;
  box-shadow: 0rem 0rem 1.5rem rgba(16, 21, 39, 0.1);

  &:after {
    border-color: var(--color-white);
    border-width: 0 0.2rem 0.2rem 0;
    content: '';
    display: none;
    height: 0.8rem;
    left: 0.5rem;
    position: absolute;
    top: 0.2rem;
    transform: rotate(45deg);
    width: 0.4rem;
  }
}

.checkmarkIndeterminate {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  border: 0.1rem solid #787e8c;
  height: 1.5rem;
  left: 0;
  margin-top: 0.1rem;
  position: absolute;
  top: 0;
  width: 1.5rem;
}

.checkboxContainerCustom {
  margin: 0 0 1rem 1rem;
  user-select: none;
  font-size: 1.6rem;
  display: flex;
  cursor: pointer;
  width: fit-content;

  > input:checked ~ .checkmarkCustom {
    .customCheckCenter {
      background-color: var(--color-white);
      height: 0.8rem;
      width: 0.8rem;
      margin: auto;
      border-radius: 50%;
    }
  }

  > input:not(:checked) ~ .checkmarkCustom {
    background-color: var(--color-white);
  }
}

.checkmarkCustom {
  height: 1.8rem;
  min-width: 1.8rem;
  border: 0.1rem solid var(--color-gray-checkbox);
  background-color: var(--color-blue-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;
}

.checkboxText {
  display: block;
  margin-top: 0.3rem;
}

.checkboxLegendWrapper {
  width: 100%;
}

.labelDisabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.subLabel {
  margin-left: 0.2rem;
  color: #787e8c;
  font-style: italic;
}

.customDisabled {
  pointer-events: none;
  opacity: 0.5;
}
