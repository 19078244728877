.checkboxContentContainer {
  display: flex;
  font-size: 1.4rem;
  font-weight: bold;
  color: #4a4a68;

  svg {
    margin-left: 0.5rem;
  }
}

.description {
  font-weight: normal;
  color: #4a4a68;
  margin-top: 0.3rem;
  font-size: 1.4rem;
}

.checkedOption {
  color: var(--color-blue-primary);
}
