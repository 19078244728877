.alertButton {
  color: var(--color-white);
  &:hover {
    color: var(--color-white);
  }
}

.toastIcon {
  margin-right: 1rem;
}

.toastAlign {
  display: flex;
  flex-direction: column;
}

.alertButton {
  color: var(--color-white);
  display: inline;
  padding-left: 0rem;
  text-decoration: underline;
  text-transform: lowercase;
  font-weight: 700;

  &:hover {
    color: var(--color-white);
  }
}
