.tagList {
  &:empty {
    display: none;
  }
  display: inline-flex;
  flex-wrap: wrap;

  div[class^='Chip_container'] {
    width: auto;
  }

  > li {
    margin: 1.6rem 1.6rem 0 0;
  }
}

.tagListWrapper {
  border: 0.1rem solid transparent;
}
