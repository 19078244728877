.addWrapper {
  border: 0.1rem solid #c9cfe4;
  border-radius: 0.8rem;
  position: relative;
  height: 3.8rem;
  width: 100%;

  &:focus-within {
    border: 0.1rem solid var(--color-blue-default);
  }
}

.addButton {
  background-color: var(--color-blue-main);
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  height: 3.7rem;
  width: 4rem;
  border: none;
  top: 0;
  right: -0.11rem;

  &:focus {
    outline: none;
  }
}

.fieldWithError {
  border-bottom: 0.1rem solid var(--color-red-default);
}

.inputField {
  font-size: 1.4rem;
  border: none;
  margin: 0 1rem;
  color: var(--color-gray-600);
  height: -webkit-fill-available;
  background-color: transparent;
  width: 100%;
  height: 100%;

  &:last-child {
    margin-bottom: 1.4rem;
  }

  &::placeholder {
    color: var(--color-gray-300);
  }

  &:focus {
    outline: none;
  }
}

.fieldErrorMessage {
  color: var(--color-red-default);
  font-size: 1.4rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.addIcon {
  color: var(--color-white);
  height: 1.6rem;
  min-width: 1.6rem;

  &:focus {
    outline: none;
  }
}
