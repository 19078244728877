.loginFormContainer {
  display: flex;
  flex-direction: column;
  place-content: center;
  min-height: 100%;
}

.smallLogo {
  width: 28rem;
  margin-bottom: 3.4rem;
}

.loginFormContent {
  background-color: var(--color-white);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  padding: 3.2rem;
  width: 33.2rem;

  border: 0.1rem solid #e9e9e9;
  box-sizing: border-box;
  box-shadow: 0 0 1.5rem rgba(16, 21, 39, 0.1);
}

.forgotPasswordLegend {
  color: var(--color-gray-400);
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: 2.5rem;
  text-align: center;
}
