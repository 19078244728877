.sectionPreviewContainer {
  background: white;
}

.section {
  border-radius: 0.8rem;
  flex: 1;
  span {
    color: black;
  }
  li {
    padding: 1rem 0;
  }

  div[id^='rich-text-editor-'] {
    width: 100%;
  }

  position: relative;
}

.readOnlySection {
  padding: 1rem;
  margin-bottom: 2rem;
}

.draggableContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dragAndDropIcon {
  width: 3rem;
  padding: 0 1rem;
}

.sectionContent {
  flex: 1;
}

.sectionContentWrapper {
  padding-top: 2.4rem;
}

.sectionTitleContainer {
  position: relative;
  padding: 1rem;
  transition: box-shadow 0.1s, background-color 0.1s;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.sectionTitleWrapper {
  position: relative;
  border-radius: 0.4rem;
  border: 0.1rem dashed #979797;
}

.removeSectiontitle {
  position: absolute;
  right: 0;
  top: calc(51% - 1.1rem);
}

.separator {
  margin-bottom: 2rem;
  margin-top: 2rem;
  border-bottom: 0.1rem solid #eeeeee;
  margin-right: -1rem;
  margin-left: 2rem;
}

.readOnlySeparator {
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid #eeeeee;
}

.sectionTitle {
  line-height: 1.5;
  text-align: left;
  color: #101527;
  width: 95%;
  padding: 0.8rem;
  font-size: 2.4rem;
}

.removeSection {
  position: absolute;
  top: 0.7rem;
  right: 0rem;
  z-index: 2;

  display: flex;
  flex-direction: row;
  align-items: center;

  label[class*='checkboxContainer'] {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.editable {
  background-color: transparent;
  &:focus {
    outline: none;
  }
}

.htmlPreview {
  font-size: 1.6rem;
  min-height: 4rem;
  padding: 0.9rem;

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  > p {
    margin-bottom: 1rem;
  }
}

.addSectionEntryContainer {
  margin-top: 1.2rem;
}

.richEditorContainer {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem 0 1rem;
  border: 0.1rem dashed var(--color-gray-400);
  border-radius: 0.5rem;
}

.btnRemoveSection {
  width: 4rem;

  &:focus {
    outline: none;
  }

  > svg {
    color: #c7c7c7;
    font-size: 2.1rem;
    background: white;
    border-radius: 100%;
  }
}

.focused {
  .removeEntry {
    display: inline-block;
  }
}
