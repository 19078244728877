.checkboxContainer {
  margin-top: 1.6rem;
  color: var(--color-gray-700);

  .permission-checkbox {
    margin-bottom: 2rem;
    margin-left: 0;
  }

  span div[class*='tooltip'] {
    max-width: 31.3rem;
  }
}

.groupUserBoxWrapper {
  margin-top: 3rem;
}

.customPermissionsWrapper {
  margin-top: 1.6rem;
  margin-left: 2.9rem;
}

.customPermissionsContentWrapper {
  margin-top: 2rem;
  margin-left: 2.9rem;

  &:empty {
    display: none;
  }
}

.publicGroupVisibilityWrapper {
  margin-left: 2.7rem;
  margin-bottom: 1rem;
}

.lockIcon {
  font-size: 2rem;
}

.leftChevron {
  font-size: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.iconsContainer {
  display: flex;
  flex-direction: column;

  > svg:nth-child(1) {
    margin-bottom: 3.5rem;
  }
}

.customPermissionsText {
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #4a4a68;
  margin: 1.6rem 0.1rem;
}
