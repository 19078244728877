.loginField {
  background-color: #f1f2f3;
  border-radius: 0.8rem;
  border-style: unset;
  color: var(--color-gray-900);
  font-size: 1.6rem;
  min-width: 100%;
  padding: 1rem 1.7rem;
  height: 3.8rem;

  &:last-child {
    margin-bottom: 1.4rem;
  }

  &::placeholder {
    color: var(--color-gray-400);
    font-size: 1.4rem;
  }

  &:focus {
    outline: none;
    background: var(--color-blue-light);
  }

  &:disabled {
    color: var(--color-gray-300);
    cursor: not-allowed;
  }
}

.fieldWithError {
  border: 0.1rem solid var(--color-red-default);
}

.fieldErrorMessage {
  display: block;
  margin-top: 0.5rem;
  color: var(--color-red-default);
  font-size: 1.4rem;
}

.loginFieldWrapper {
  margin-bottom: 0.8rem;
}
