.noResultsFoundContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.noResultsFoundTitle {
  color: var(--color-blue-strong);
  font-size: 1.8rem;
}

.noResultsFoundLegend {
  color: #787e8c;
  font-size: 1.6rem;
  margin-top: 0.8rem;
  text-align: center;
}

.noResultsFoundIcon {
  > svg {
    color: #787e8c;
    font-size: 3.5rem;
    opacity: 0.4;
  }
}
