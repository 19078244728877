@import '../Button/Button.module.scss';

.downloadLink {
  @extend .btn, .primary, .blue;
  margin-top: 2rem;

  > svg {
    margin-right: 1.5rem;
  }
}
