div[class^='RichTextEditor__root__'] {
  background: #ffffff;
  border-radius: 0.3rem;
  border: none;
  font-family: Lato;
  font-size: 1.6rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #535353;
  width: 100%;
  position: relative;
}

div[id^='rich-text-editor-'] {
  width: 83%;
  background: var(--color-white);
}

@media (max-width: 850px) {
  div[id^='rich-text-editor-'] {
    width: 70%;
  }
}

div[class^='public-DraftEditorPlaceholder-root'] {
  font-style: italic;
}

button[class^='IconButton__root___'] {
  background: none;
  background-color: #d9d8d9;
  border: none;
}

div[class^='EditorToolbar__root___'] {
  background-color: #d9d8d9;
  padding: 0.5rem;
  border-radius: 2rem;
  position: absolute;
  z-index: 2;
  border-bottom: none;
  top: 1rem;
}

div[class^='ButtonGroup__root___'] {
  margin: 0;
}

button[class^='IconButton__isActive___'] {
  background-color: black;
}

div.public-DraftStyleDefault-block > span {
  color: var(--color-gray-400);
  font-weight: 400;
}

.richEditor .notranslate.public-DraftEditor-content:focus,
.richEditor .notranslate.public-DraftEditor-content:hover {
  background-color: transparent;
}

div[class*='InputPopover__checkOption'] {
  display: none;
}
