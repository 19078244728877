.icon {
  color: var(--color-gray-300);
  transition: color 0.2s ease;

  &:hover {
    color: #214b7d;
  }
}

.inline {
  display: inline-block;
}
.tooltip {
  max-width: 38rem;
  border-radius: 0.8rem !important;
  opacity: 1 !important;
  padding: 0.8rem 1rem !important;
}

.wordBreakAll {
  word-break: break-all;
  white-space: break-spaces;
}

.widthAuto {
  max-width: initial;
}

.text {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: normal;
  white-space: normal;

  a {
    text-decoration: underline;
    font-weight: 500;
  }
}

.arrow {
  opacity: 0;
}

.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
