.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 6rem 0 3.9rem 0;
  @media (min-height: 1080px) {
    padding-top: 12rem;
  }
}

.logo {
  margin-bottom: 4rem;
  max-height: 6.3rem;
  width: auto;
  margin-bottom: 4rem;
  max-width: 43rem;
}

.customLoginDefaultLogo {
  @media (max-width: 1215px) {
    fill: var(--color-white);
  }
}

.form {
  padding-bottom: 5.6rem;
  padding-top: 6.5rem;
  position: relative;
}

.title {
  color: #000000;
  font-size: 2.4rem;
  margin-bottom: 2.5rem;
  margin-top: 0;
  text-align: center;

  @media (max-width: 1036px) {
    text-align: center;
  }
}

.legend {
  color: var(--color-gray-500);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  margin-bottom: 2.2rem;
}

.errorLegend {
  color: var(--color-red-default);
  font-size: 1.4rem;
  font-weight: 700;
}

.backNavigation {
  color: var(--color-gray-600);
  font-size: 1.6rem;
  left: 2.1rem;
  position: absolute;
  top: 2.2rem;
  cursor: pointer;
}

.link {
  color: var(--color-blue-default);
  text-decoration: underline;
}

.termsOfUse {
  > span {
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin: 1rem 0 1.6rem 0;
  }
}

.overlayWrapper {
  display: block;
  @media (min-width: 76rem) {
    display: none;
  }
}

.formWrapper {
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.googleButton {
  min-width: 0;
  width: 23.9rem;
  align-self: center;
}

.loginButton {
  background-color: var(--color-blue-primary);
  margin: 2.4rem 0;
  font-weight: 700;
}

.rememberMeWrapper {
  display: flex;
  justify-content: center;
}

.copyRightWrapper {
  flex: 1;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.copyrightResetPasswordWrapper {
  margin-top: 4rem;
}
