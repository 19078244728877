.sendStepContentWrapper {
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
}

.sendContainer {
  background-color: var(--color-white);
  width: 31%;
  height: fit-content;
  min-width: 37rem;
  margin-right: 3.2rem;
  border-radius: 0.8rem;
}

.content {
  height: min-content;
  margin: auto;
  width: 100%;
  padding: 3.6rem 3.4rem;

  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }

  @media (max-width: 1250px) {
    padding: 2rem 4rem;
  }
}

.contentLoader {
  height: 49rem;
}

.inputsContainer {
  margin-top: 2.6rem;
}

.recipientsContainer {
  margin: auto;
  margin-bottom: 3.3rem;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1.4rem;

  p {
    font-weight: 400;
    color: rgba(74, 74, 104, 1);
    margin-bottom: 1rem;
  }

  p:nth-child(2) {
    font-size: 1.8rem;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 0;
  }

  span {
    font-weight: 500;
    text-transform: lowercase;
  }

  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.datePickerContainer {
  margin-top: 2rem;
}

.scheduleQuestion {
  font-size: 1.2rem;
  font-weight: 700;
  color: rgba(74, 74, 104, 1);
  margin-bottom: 1rem;
  margin-top: 2.4rem;
  margin-left: 0.8rem;
}

.hidden {
  display: none;
}

.article {
  padding: 2.4rem;
  background: white;
  border-radius: 0.8rem;
  height: fit-content;

  h3 {
    color: #101527;
    font-size: 1.8rem;
    font-weight: 400;

    svg {
      margin-right: 0.6rem;
    }
  }

  p {
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 0.4rem;
    color: #787e8c;
  }
}

.iconWrapper {
  width: 3.6rem;
  height: 3.6rem;
  border: 0.1rem solid rgba(233, 233, 233, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  color: rgba(120, 126, 140, 1);
}

.column {
  display: flex;
  flex-direction: column;
}

.recipientsCountText {
  margin: 0 0 0.7rem 0;
}

.divider {
  height: 0.1rem;
  background: rgba(233, 233, 233, 1);
  width: 100%;
}

.emailBackground {
  flex: 1;
  border-radius: 0.8rem;
  overflow: hidden;
  background: #ffffff;
}
