.tabs {
  position: relative;
}

.tabContainer {
  display: flex;
  align-items: center;
  height: 4.8rem;
  margin-bottom: 2rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tabContainerBorder {
  box-shadow: inset 0 -1px 0px rgb(120 126 140 / 20%);
}

.title {
  margin: 1.3rem 1.6rem;
  font-size: 1.5rem;
  font-weight: normal;
  text-transform: capitalize;
  color: #787e8c;
  transition: color 0.3s ease-in-out;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;    
    color: var(--color-blue-primary);
  }
}

.titleSpan {
  padding-left: 1rem;
}

.titleActive {
  color: #101527;

  &:hover {
    opacity: 1;
  }
}

.indicator {
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.tabItemContainer {
  position: relative;
}

.activeIndicator {
  overflow: hidden;
  border-bottom: 3px solid #131313;
  margin-bottom: -3px;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.hiddenTab {
  display: none;
}
.visibleTab {
  display: block;
  animation: fadeIn 0.4s;
}
