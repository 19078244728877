.fundWrapper {
  margin-bottom: 1rem;
}

.subHeader {
  display: flex;
  align-items: center;
}

.icon {
  color: #444444;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  justify-content: center;
  margin-right: 0.6rem;
}

.title {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 0.5rem;
  padding-top: 0.8rem;
}
