.growthIndicator {
  width: 0;
  height: 0;
  padding-top: 0.2rem;
  display: inline;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-radius: 0.1rem;
}

.up {
  border-bottom: 0.6rem solid #38b8a5;
}

.down {
  border-top: 0.6rem solid #e95565;
}

.growthPercentage {
  padding-left: 0.4rem;
  font-size: 1.3rem;
}

.growthValue {
  padding-left: 0.2rem;
  font-weight: 400;
  font-size: 1.8rem;
}

.bold {
  font-weight: 600;
}

.positiveGrowth {
  color: #38b8a5;
}

.negativeGrowth {
  color: #e95565;
}
