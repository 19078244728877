.container {
  max-width: 37rem;
  position: relative;
  padding: 2.8rem;
  border-radius: 0.6rem;
  box-shadow: 0 0rem 0.8rem 0.4rem rgba(55, 56, 60, 0.1);
  border: solid 0.1rem var(--color-gray-200);
  background-color: var(--color-white);
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 1.6rem;
  font-weight: 800;
  color: #194590;
  word-break: break-all;
  text-align: center;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(256, 256, 256, 0.8);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vh;
  height: calc(100% - 4rem);
}

.legend {
  color: #787e8c;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
}
