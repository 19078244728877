.iframe {
  background-color: transparent;
  padding: 3rem;
  min-height: 85.8vh;
  height: 100%;
  width: 100%;
  border-radius: 3.9rem;

  @media (max-width: 768px) {
    padding: 0rem;
    box-shadow: none;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}
