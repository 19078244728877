.btn {
  transition: box-shadow 0.1s ease-in-out;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  height: 4rem;
  width: 12.5rem;
  text-transform: uppercase;
  width: fit-content;

  &:hover {
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.14);
  }

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.full {
  min-width: 100%;
}

.primary {
  background-color: var(--color-gray-900);
  color: var(--color-white);

  &:hover {
    background: #214b7d;
  }
}

.secondary {
  background-color: transparent;
  color: var(--color-gray-300);
  cursor: pointer;
  border: 0.1rem solid var(--color-gray-300);
  border-radius: 0.4rem;
  min-width: 12.5rem;
  font-weight: 500;
  font-family: Lato;
  transition: background 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-gray-100);
    box-shadow: none;
  }
}

.blue {
  background-color: var(--color-blue-dark);
  color: var(--color-white);
  padding: 1rem 1.3rem;
  width: fit-content;
}

.large {
  width: 12.5rem;
}

.xlarge {
  width: 15.1rem;
}

.autoWidth {
  width: auto;
  padding: 0 1rem;
}

.link {
  -webkit-font-smoothing: auto;
  background-color: transparent;
  border: none;
  color: var(--color-gray-700);
  font-size: 1.6rem;
  font-weight: 300;
  margin-right: 1rem;
  padding-left: 1rem;
  text-decoration: underline;
  text-transform: capitalize;
  width: fit-content;

  &:hover {
    box-shadow: none;
    color: var(--color-gray-800);
  }
}

.disabled {
  cursor: not-allowed;
}

.rounded {
  border-radius: 0.8rem;
}

.strong {
  font-weight: 600;
}

.angleRight {
  margin-left: 1rem;
  font-size: 2rem;
}

.angleLeft {
  margin-right: 1rem;
  font-size: 2rem;
}

.btnBack {
  padding: 1rem;
  border: 0.2rem solid var(--color-blue-light-secondary);
  color: var(--color-blue-main);
}

.navigation {
  display: flex;
  align-items: center;
}

.danger {
  padding: 1rem 1.3rem;
  background: #e75767;
  color: var(--color-white);
}
