.wrapper {
  min-height: 100vh;
  width: 100%;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 140rem;
  padding: 3rem 0;

  @media (max-width: 1450px) {
    max-width: 110rem;
  }
  @media (max-width: 1200px) {
    max-width: 70rem;
  }
  @media (max-width: 730px) {
    max-width: 60rem;
  }
  @media (max-width: 730px) {
    max-width: 100%;
    padding: 0 2rem;
  }
}

.title {
  color: var(--color-gray-900);
  font-size: 2.8rem;
  text-align: center;
}

.content {
  color: var(--color-gray-400);
  font-size: 1.5rem;
  line-height: 2.6rem;
  max-height: 65vh;
  overflow: auto;
  white-space: break-spaces;

  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-gray-100);
    border-radius: 10rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10rem;
    width: 0.3rem;
    background: var(--color-blue-dark);
  }
}

.btnAcceptTerms {
  margin-top: 2rem;
  width: 30rem;
}

.separator {
  height: 1rem;
  margin: 3rem 0;
  width: 100%;
}

.link {
  color: var(--color-blue-default);
  text-decoration: underline;
}

.termsOfUse {
  > span {
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin: 0.3rem 0;
  }
}
