.textAreaContainer {
  border-radius: 0.5rem;
  border: 0.1rem dashed var(--color-gray-400);
  color: var(--color-gray-600);
  font-family: 'Lato', sans-serif;
  font-size: 1.6rem;
  min-height: 20rem;
  padding: 1rem;
  resize: none;
  width: 100%;

  &:focus {
    outline: none;
    border-color: var(--color-blue-default);
  }

  &:disabled {
    color: var(--color-gray-500);
    background-color: var(--color-gray-100);
    cursor: not-allowed;
  }

  &::placeholder {
    color: var(--color-gray-300);
    font-weight: 400;
    font-size: 1.6rem;
  }
}

.textAreaError {
  @extend .textAreaContainer;
  border-color: var(--color-red-default);
}

.fieldErrorMessage {
  color: var(--color-red-default);
  font-size: 1.4rem;
}

.textAreaWrapper {
  position: relative;
}