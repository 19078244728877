.btnAddNewSection {
  width: 100%;

  > svg {
    margin-right: 0.5rem;
  }
}

.emailSectionAccordionWrapper {
  margin-bottom: 1rem;
  height: 4rem;
}

.emailSelectionCardBody {
  min-height: 24rem;
  max-height: 51vh;
  overflow: auto;

  -webkit-overflow-scrolling: auto;
}

.addSectionWrapper {
  margin-top: 2rem;

  > h4 {
    margin-bottom: 1rem;
  }

  div {
    width: 100%;
  }
}
