.addEmailRecipientsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.boxContainerAdd {
  background-color: var(--color-white);
  width: 31%;
  min-width: 37rem;
  margin-right: 3.2rem;

  padding: 3rem;
  border-radius: 0.8rem;
  min-height: 15rem;
  height: fit-content;

  @media (max-width: 1024px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3.2rem;
  }
}

.recipientsContainer {
  flex: 1;
}

.boxInside {
  padding: 3rem;
  border-radius: 0.8rem;
  height: min-content;

  .usersBox {
    div {
      border: none;
      max-height: 50vh;
    }

    > div[class^='BoxGroupsAndUsersChips'] {
      grid-template-columns: repeat(auto-fill, 15.4rem);
      column-gap: 0.5rem;

      > div[class^='Chip_container'] {
        margin-left: 0rem;
      }
    }
  }
}

.noRecipients {
  font-size: 1.4rem;
  color: #787e8c;
  margin-top: 2rem;
}

.tooltip {
  position: absolute;
  right: -2rem;
  top: -2rem;
  font-size: 1.6rem;
  svg {
    color: var(--color-gray-300);
  }
}

.pTag {
  font-size: 1.6rem;
  margin-bottom: 1rem;
  position: relative;
}

.addGroupUsers {
  margin-bottom: 2.5rem;
}

.titleContainer {
  margin-bottom: 1.7rem;
}

.separator {
  width: 100%;
  height: 0.1rem;
  background-color: #e9e9e9;
  margin: 2rem 0;
}

.text {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #4a4a68;
}

.usersBox {
  padding-top: 1rem;
}
