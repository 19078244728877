.searchInputWrapper {
  position: relative;
  width: 100%;
  max-width: 40rem;
  text-align: left;
}

.searchInput {
  background: var(--color-white);
  border-radius: 10rem;
  border: 0.05rem solid var(--color-gray-300);
  font-size: 1.6rem;
  height: 4rem;
  padding: 0.5rem 1.5rem!important;
  width: 100%;

  &::placeholder {
    color: var(--color-gray-300);
  }

  &:focus {
    outline: none;
  }
}

.btnSearch {
  background: var(--color-blue-main);
  border-radius: 10rem;
  height: 3.8rem;
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
  width: 3.8rem;

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    font-size: 1.5rem;
    color: var(--color-white);
    top: 1.2rem;
    right: 1.1rem;
  }
}
