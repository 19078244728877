.heading {
  color: var(--color-gray-400);
  font-size: 1.5rem;
}

.h1 {
  font-size: 2.25rem;
}

.h3 {
  color: var(--color-gray-900);
  font-size: 2.4rem;
  font-weight: 400;
}
