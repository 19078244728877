.footer {
  display: flex;
  height: 6rem;
  justify-content: space-around;
  align-items: center;
  margin: 0 4.5rem;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    text-align: center;
    margin: 0 1rem;
  }
}

.footerList {
  flex: 1.5;
  line-height: 1.8rem;

  > li {
    color: var(--color-gray-400);
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 400;
    margin-right: 1.2rem;
    text-transform: capitalize;

    a {
      transition: color 0.2s ease-in-out;
    }

    &:hover a {
      color: var(--color-gray-700);
    }
  }

  > li:last-child {
    margin-right: 0;
  }
}

.copyrightList {
  @extend .footerList;
  flex: 1;
}

.linksList {
  @extend .footerList;

  > li:first-child {
    color: var(--color-blue-dark);
    font-weight: bold;
  }
}
