.pdfPreviewer {
  &:hover .pageControls,
  &:hover .downloadLink,
  &:hover .zoomContainer {
    opacity: 1;
  }

  div[class*='react-pdf__message'] {
    align-items: center;
    display: flex;
    font-size: 1.5rem;
    font-weight: 300;
    justify-content: center;
    min-height: 5rem;
  }
}

div[class*='react-pdf__Document'] {
  max-height: 85vh;
  max-width: 75rem;
}

div[class*='react-pdf__Page__svg'] {
  margin: 0 auto;
}

.pageControls {
  align-items: center;
  background: var(--color-white);
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  top: -5.5rem;

  > button {
    background: var(--color-white);
    border-radius: 0.4rem;
    border: 0;
    font-size: 2rem;
    font-weight: 500;
    font: inherit;
    height: 4.4rem;
    width: 4.4rem;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  > span {
    font-size: 0.8em;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0 0.5em;
  }
}

.downloadLink {
  border-radius: 10rem;
  left: 2rem;
  margin: 0;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
  top: -5.5rem;

  > svg {
    margin: 0;
  }
}

.zoomContainer {
  align-items: center;
  background: var(--color-white);
  border-radius: 0.4rem;
  opacity: 0;
  position: absolute;
  transition: opacity ease-in-out 0.2s;
  left: 10rem;
  top: -5rem;

  > button {
    width: 3rem;
    height: 3rem;

    &:focus {
      outline: none;
    }
  }
}
