.leftColumn {
  display: flex;
  flex-direction: column;
  width: 31%;
  min-width: 37rem;
  margin-right: 3.2rem;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.rightColumn {
  flex: 1;

  @media (max-width: 1200px) {
    width: 100%;
    margin: 1rem 0;
  }
}

.attachmentsWrapper {
  margin-top: 1rem;
}

.sticky {
  position: sticky;
  top: 1rem;
  z-index: 2;
}
