@import 'variables.scss';

.rowContainer {
  transition: transform 0.2s;

  &:focus {
    outline: none;
    border-radius: 0.8rem;
    border: 0.1rem solid rgba($primary-blue, 0.25);
  }
}

.marginBottom {
  margin-bottom: 2.4rem;
}

.portfolioBody {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  div[class*='Card_cardWrapper'] {
    padding: 2.4rem 2.1rem 1rem;
  }
}

.investPortfolioBody {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  grid-gap: 1rem;
  margin-top: 1.3rem;

  @media (min-width: 640px) {
    flex-direction: row;
  }
}

.chartContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: 1280px) {
    flex-direction: row;
  }

  &:empty {
    display: none;
  }
}

.chart {
  width: 12rem;
  height: 12rem;
  border-radius: 12rem;
  background-color: var(--color-gray-300);
  margin: auto;

  @media (max-width: 768px) {
    height: 18rem;
    width: 18rem;
  }
}

.portfolioLoaderBody {
  height: 17rem;
}

.portfolioBodyWrapper {
  max-height: 13.5rem;
  padding-left: 5rem;
  overflow: hidden;

  &__open {
    max-height: unset;
    height: unset;
  }
}

.company {
  margin: 0.8rem 0.8rem 0.8rem 0rem;

  div[class*='Chip_container'] {
    max-width: auto;
    width: 15.6rem;
  }

  div[class*='Chip_container'] {
    &:hover {
      background-color: var(--color-gray-200);

      p {
        color: var(--color-gray-500);
      }
    }
  }
}

.chipButton {
  &:focus {
    outline: none;
  }

  div[class*='Chip_container'] {
    justify-content: center;

    p {
      font-weight: 700;
      color: var(--color-black);
    }
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 4fr;
  }
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lastUpdatedDate {
  color: #787e8c;
  font-weight: 400;
  font-size: 1.4rem;
  text-transform: none;
  text-align: end;
  justify-self: flex-end;
  display: block;

  @media (min-width: 468px) {
    display: none;
  }

  span {
    margin-left: 0.4rem;
  }
}

.lastUpdateHeader {
  @extend .lastUpdatedDate;
  display: none;

  @media (min-width: 468px) {
    display: block;
  }
}

.portfolioIcon {
  text-transform: capitalize;
  color: #101527;
  font-size: 1.6rem;
  font-weight: 400;

  svg {
    margin-right: 0.7rem;
  }
  @media (max-width: 768px) {
    grid-row: 1 / 3;
  }
}
.portfolioName {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 0.5rem;
  text-transform: none;
  color: #000000;
  margin-left: 1.2rem;
}

.portfolioNameHidden {
  font-size: 1.6rem;
  padding: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  height: 0px;
  overflow: hidden;
}

.createdAt {
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--color-gray-300);
  font-style: italic;
  padding-top: 0.1rem;

  @media (max-width: 768px) {
    grid-column: 2 / 2;
  }
}

.dropdownContainer {
  @media (max-width: 768px) {
    width: 20rem;
    position: fixed;
    top: 3.2rem;
    right: 2rem;
    z-index: 3;

    div[class*='OptionsDropdown_dots'] {
      color: white;
    }
  }
}

.companies {
  font-size: 1.4rem;
  font-weight: 400;
  justify-self: flex-end;
  width: 20.6rem;
  flex: 1;
  text-align: end;
  text-transform: lowercase;
  color: #787e8c;

  @media (max-width: 768px) {
    display: none;
  }
}

.totalHeader {
  min-width: 20rem;
  text-align: left;
  font-size: 1.8rem;
  font-weight: normal;
  padding-bottom: 0.8rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.percentageRow {
  align-items: baseline;
  display: grid;
  grid-template-columns: 0.8rem auto;
  gap: 0.8rem;

  padding-bottom: 0.4rem;

  font-weight: normal;
  font-size: 1.2rem;

  color: #4a4a68;
}

.resultContainer {
  padding: 1rem 0;
  margin-right: 1rem;
  align-self: center;

  @media (max-width: 1024px) {
    margin: 0;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0 1rem;
  }
}

.clickableStyles {
  cursor: pointer;

  &:focus {
    outline: none;

    > div {
      border: 0.1rem solid rgba($primary-blue, 0.25);
      border-radius: 0.8rem;
    }
  }

  &:hover {
    transform: translateY(-0.2rem);
  }
}

.legendIndicator {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.8rem;
}

.legendName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 14rem;
}
