.updateContainer {
  @media (max-width: 768px) {
    margin-bottom: 10rem;
  }

  @media (max-width: 1024px) {
    div[class*='contentContainerEdit'] {
      padding-bottom: 0;
    }

    div[class*='cardWrapper'] {
      padding: 2.4rem;
      margin-bottom: 2rem;
    }
  }
}

.bodyContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 1024px) {
    div[class*='contentContainerEdit']
      > div[class*='cardWrapper']:nth-child(2) {
      position: absolute;
      top: calc(100% + 2rem);
    }
  }

  @media (max-width: 1040px) {
    display: block;
  }
}

.contentContainer {
  flex: 1;
  margin-right: 3.2rem;

  height: fit-content;

  &:first-child {
    padding-bottom: 5rem;
  }

  @media (max-width: 1040px) {
    width: 100%;
  }
}

.contentContainerEdit {
  @extend .contentContainer;
  width: calc(100% - 42rem);

  @media (min-width: 1041px) and (max-width: 1250px) {
    width: calc(100% - 35rem);
  }

  @media (max-width: 1040px) {
    width: 100%;
  }
}

.card.override {
  padding: 2.2rem 3.2rem;
  margin-bottom: 3.2rem;
  height: 8.1rem;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;

  button:first-child {
    margin-right: 1rem;
  }

  @media (max-width: 767px) {
    justify-content: space-between;
  }
}

.updateCardContainer {
  width: 40rem;

  @media (max-width: 1040px) {
    width: 100%;
    margin-top: 2rem;
  }

  @media (min-width: 1041px) and (max-width: 1250px) {
    width: 33rem;
  }
}

.updateTabs {
  div[class*='Card_cardWrapper'] {
    padding: 1rem 2.1rem 2.4rem 2.1rem;
  }
}

.hidden {
  display: none;
}

.expandCard {
  width: 90%;
}

.collapseCard {
  width: auto;

  div[class*='updateCardContainer'] {
    padding: 2rem 1.8rem;
    text-align: center;
  }
}

.collapsableCardContainer {
  width: calc(10% - 1.5rem);

  > div[class^='ShowUpdatePermission'] {
    padding: 2.5rem 2rem;
  }
}
