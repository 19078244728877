.link {
  color: var(--color-blue-primary);
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  padding-left: 1.7rem;
  letter-spacing: 0.03em;
  margin-top: -0.3rem;
  text-decoration: underline;
}
