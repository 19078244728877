.sectionImage {
  position: relative;
  margin: 2rem 0;

  img {
    width: 100%;
  }

  &:hover {
    .imageUploader,
    .overlay {
      display: inline-block;
    }
  }
  &:focus {
    outline: none;
  }
}

.smallImage {
  img {
    width: 30rem;
    margin: auto;
    padding-right: 4rem;
  }
}

.pointer {
  cursor: pointer;
}

.imageUploader {
  display: none;
  position: absolute;
  top: calc(50% - 1.5rem);
  right: calc(50% - 1rem);

  padding: 0.7rem;
  background: white;
  border-radius: 100%;
  svg {
    font-size: 1.4rem;
    color: #333333;
  }
}

.overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.btnRemoveSection {
  position: absolute;
  top: calc(50% - 1.1rem);
  right: 0;

  width: 4rem;
  > svg {
    color: var(--color-gray-300);
    font-size: 2.1rem;
    background: white;
    border-radius: 100%;
  }

  &:focus {
    outline: none;
  }
}

.uploadIcon {
  font-size: 3rem;
  color: white;
}

.switchContainer {
  position: absolute;
  z-index: 99;
  top: 0.8rem;
  left: 0.8rem;
}
