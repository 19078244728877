.legend {
  margin-top: 2.35rem;
  color: #4a4a68;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 0.6rem;
  text-align: center;
}

.link {
  color: var(--color-blue-primary);
  font-weight: 700;
  font-size: 1.4rem;
  margin-left: 0.5rem;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  text-decoration: underline;
}
