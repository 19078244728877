@import '../../variables.scss';

.modalOverlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9997;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blurOverlay {
  backdrop-filter: blur(0.4rem);
}

.modalWrapper {
  background: var(--color-white);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  min-width: 46rem;
  max-width: 95%;
  max-height: 95%;
  position: fixed;
  transform: translate(-50%, 0%);

  @media (max-width: $breakpoint-md) {
    min-width: auto;
  }
}
