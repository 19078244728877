// This file is create because Styled Components does not support @container

.collapsible button {
  div:nth-child(2) {
    @container (max-width: 120px) {
      margin-left: 0.4rem;
    }
  }
}

button.createChat {
  @container (max-width: 200px) {
    width: 2.8rem;
    height: 2.8rem;
    min-width: 2.8rem;
    min-height: 2.8rem;
  }
}

.channelPreviewContainer {
  &:hover {
    .members {
      @container (max-width: 96px) {
        display: none;
      }
    }
  }
}
