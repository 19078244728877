.timelineItemDotContainer {
  border-radius: 50%;
  color: var(--color-white);
  font-size: 1.8rem;
  height: 4rem;
  left: -0.2rem;
  margin-left: -1.3rem;
  padding-top: 0.6rem;
  position: absolute;
  top: 0;
  width: 4rem;

  > svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.announcementContainer {
  background: var(--color-red-default);
  @extend .timelineItemDotContainer;
}

.draftAnnouncement {
  background: #e68993;
}

.iueContainer {
  background: var(--color-teal-default);
  @extend .timelineItemDotContainer;
}

.draftIue {
  background: #a6f7e1;
}

.documentContainer {
  background: var(--color-blue-bright);
  @extend .timelineItemDotContainer;
}

.draftDocument {
  background: #839bde;
}

.accountingContainer {
  background: var(--color-purple-default);
  @extend .timelineItemDotContainer;
}

.xeroContainer,
.quickbooksContainer {
  @extend .timelineItemDotContainer;
}

.draftAccounting {
  background: #a898c3;
}

.noteContainer {
  background: var(--color-yellow-default);
  @extend .timelineItemDotContainer;

  > svg {
    left: 50%;
    top: 50%;
  }
}
.drafNote {
  background: #f4cc6d;
}

.transactionContainer {
  background: var(--color-orange-default);
  @extend .timelineItemDotContainer;
}

.draftTransaction {
  background: #f6ad74;
}

.timelineItemDotContainerDark {
  border-radius: 50%;
  color: var(--color-white);
  font-size: 1.8rem;
  height: 4rem;
  padding-top: 0.6rem;
  width: 4rem;
  position: relative;

  > svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.groupLogoStyles,
.companyLogoStyles {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10rem;
  border: 1px solid #e9e9e9;
  width: 3.4rem;
  height: 3.4rem;
  position: relative;
  margin-left: -1.2rem;
}

.withGroupLogo,
.withCompanyLogo {
  bottom: -0.5rem;
  height: 1.8rem;
  left: unset;
  position: absolute;
  right: -0.5rem;
  top: unset;
  width: 1.8rem;

  > svg {
    font-size: 1rem;
  }
}

.withGroupLogo {
  bottom: -1.6rem;
  right: -1.9rem;
}

.portfolioIconContainer {
  margin-left: -1.4rem;
}

.checkIcon {
  border: solid 0.2rem #e9e9e9;
  background-color: var(--color-white);
  > svg {
    color: #c9cfe4;
  }
  &:hover {
    border: solid 0.2rem #c9cfe4;
  }
}

.checkedIcon {
  > svg {
    color: #194590;
  }
}

.noFocus {
  &:focus {
    outline: none;
  }
}

.analyticsContainer {
  background: var(--color-gray-900);
  @extend .timelineItemDotContainer;
}

.uploadFileContainer {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, #00aeff 0%, #0173ff 100%);
  @extend .timelineItemDotContainer;
}

.metricsContainer {
  @extend .analyticsContainer;
  font-size: 1.6rem;
}
