.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-primary);
  border-radius: 10rem;
  text-transform: uppercase;
  color: var(--color-white);
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.3rem 0.8rem;
  width: fit-content;
  height: 2rem;
}
