.logo {
  background-size: cover;
  background-repeat: no-repeat;
  border: .2rem solid #C9CFE4;
  flex-shrink: 0;
  height: 12rem;
  width: 12rem;
  border-radius: 100%;

  @media (max-width: 1410px) {
    width: 7rem;
    height: 7rem;
    border-width: .1rem;
  }
}
