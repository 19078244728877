.listsBoxWrapper {
  margin: 1.6rem 0 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.3rem 3.2rem;

  max-height: 20rem;
  overflow-y: auto;

  label {
    color: #4a4a68;

    input {
      display: none;
    }
  }

  .circleIcon {
    width: 0.5rem;
    height: 0.5rem;

    color: var(--color-blue-dark);
  }
}

.selectListsTag {
  font-size: 1.4rem;
  color: #787e8c;
  text-transform: uppercase;
  font-weight: bold;
}

.noListsText {
  margin: 0rem 0 1rem 0;
  font-size: 1.4rem;
  color: #787e8c;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;

  a {
    margin-left: 0.5rem;
  }
}

.column {
  flex-direction: column;
  padding: 2.4rem;
  border: 0.1rem solid #f5f6f5;
  grid-column: 1/3;
  justify-content: center;
  align-items: center;

  p,
  h2 {
    text-align: center;
    max-width: 38.8rem;
  }
}

.goToWrapper {
  font-size: 1.4rem;
  color: #787e8c;
  margin-top: 2.4rem;

  p {
    padding-top: 0.1rem;
  }

  a {
    color: #101527;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    padding-top: 0.1rem;
  }
}

.infoIcon {
  font-size: 1.6rem;
  color: #787e8c;
  margin-right: 0.6rem;
}

.marginLeft {
  margin-left: 0.5rem;
}

.title {
  color: #4a4a68;
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
  margin: 0 auto;
}

.legend {
  font-size: 1.4rem;
  color: #787e8c;
  margin: 0 auto;
  margin-top: 0.6rem;
}

.link {
  font-weight: 700;
  font-size: 1.4rem;
  text-decoration: underline;
  margin: 0 auto;
  width: 100%;
  display: block;
  text-align: center;
  margin-top: 1.6rem;
  color: #194590;
}
