.updatesFeedContainer {
  background-color: var(--color-white);
  border-radius: 0.8rem;
  padding: 1rem 2rem 2rem 2rem;
  position: relative;

  @media (max-width: 768px) {
    padding: 1rem;
    max-width: 100vw;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (min-width: 1643px) {
    max-width: 100%;
  }

  div[class*='Update_updateCard'] {
    max-width: 100%;
  }

  div[class*='Tabs_title'] {
    font-size: 1.4rem;
  }
}

.mainLoaderContainer {
  height: 50vh;
}

.paginationLoaderContainer {
  margin-bottom: 2rem;
}

.noUpdates {
  color: var(--color-light-gray);
  font-weight: 400;
  font-size: 1.8rem;
  text-align: center;
  padding: 2rem 0 6rem 0;
}
