@import '../../variables.scss';

.listContainerGrid {
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: repeat(auto-fill, 19.8rem);
  padding: 2.65rem 0rem;

  @media (max-width: $breakpoint-md) {
    padding: 0 2.1rem;
  }
}

.listFundPortfolioContainerGrid {
  @extend .listContainerGrid;
  justify-content: start;
  padding: 2.65rem 0rem 0.85rem 0rem;

  li {
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
  }

  @media (max-width: $breakpoint-md) {
    justify-content: center;
  }
}

.listContainerRows {
  grid-template-columns: 1fr;
  padding: 1.8rem 2.2rem 0rem 0rem;

  li {
    margin-bottom: 2.5rem;
  }

  @media (max-width: $breakpoint-md) {
    padding: 0 2.1rem;
    li {
      margin-bottom: 1.6rem;
    }
  }
}

.flexRow {
  flex-direction: row;
  flex-wrap: wrap;
}
