.confirmationBody {
  color: var(--color-gray-600);
  font-size: 1.4rem;
}

.confirmationFooter {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button:not(:last-child) {
    min-width: 8rem;
    margin-right: 1rem;
  }
}
