.logo {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-right: 1.5rem;
  visibility: hidden;
  width: 20rem;
  fill: var(--color-white);

  @media (min-width: 1023px) {
    visibility: visible;
  }
}

.brand {
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  font-weight: normal;
}

.label {
  background-color: var(--color-blue-dark);
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
}

.productName {
  color: var(--color-white);
  margin: auto 1rem;
}

