.list {
  border-radius: 0.8rem;
  border: 0.1rem solid #e9e9e9;
  max-height: 50rem;
  overflow: auto;
  width: 100%;
}

.row {
  color: var(--color-gray-600);
  font-size: 1.6rem;
  height: 6rem;
  min-height: 6rem;
  width: 100%;
  min-width: 40rem;
}

.row:not(:last-child) {
  border-bottom: 0.1rem solid #e9e9e9;
}

.rowWidthAuto {
  min-width: auto;
}

.rowHeightAuto {
  min-height: auto;
  height: auto;
}

.row div {
  vertical-align: middle;
}

.striped li:nth-child(even) {
  background-color: var(--color-white);
}

.striped li:nth-child(odd) {
  background-color: var(--color-row-odd);
}

.emptyList {
  font-size: 1.2rem;
  font-style: italic;
  text-align: center;
}

.noTopBorderRadius {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.overflowVisible {
  overflow: visible;
}

.maxHeightNone {
  max-height: none;
}
