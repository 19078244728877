.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  align-items: center;
  background: var(--color-gray-200);
  border-radius: .4rem;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: 4rem;
  padding: .7rem 1rem;
  transition: width .2s ease-in-out;

  &:focus {
    outline: none;
  }
}

.body {
  background: var(--color-white);
  border-bottom-left-radius: .4rem;
  border-bottom-right-radius: .4rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0rem solid transparent;
  border: .2rem solid var(--color-gray-200);
  font-size: 1.6rem;
  overflow: visible;
  padding: 2rem 2.1rem;  
}

.removeBottomCornerRadius {
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}
