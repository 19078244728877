.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sectionHeader {
  display: flex;
  @extend .truncate;
}

.sectionName {
  background: transparent;
  color: var(--color-gray-800);
  font-size: 1.6rem;
  font-weight: 400;
  width: 100%;
  -webkit-font-smoothing: auto;
  @extend .truncate;
  margin-left: 1.5rem;

  &:focus {
    outline: none;
  }
}

.editMode {
  @media (max-width: 1440px) {
    max-width: 11.7rem;
  }
}

.leftSideContainer,
.rightSideContainer {
  align-items: center;
  display: flex;
  @extend .truncate;
}

.rightSideWrapper {
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin: 0 1.2rem;
  }
}
.leftSideContainer {
  margin-right: 1rem;
}

.headingDisplay {
  display: flex;
  justify-content: space-between;
  padding-right: 0.5rem;
  width: 100%;
}

.toggle {
  margin-left: 1.2rem;
  > svg {
    color: var(--color-gray-300);
    font-size: 2.3rem;
    transition: all 0.3s ease-in-out;
  }
}

.rotateAngleUp {
  transform: rotate(180deg);
  cursor: pointer;
}

.btnRemoveSection {
  &:focus {
    outline: none;
  }

  > svg {
    color: var(--color-gray-300);
    font-size: 2.1rem;
    background: white;
    border-radius: 100%;
  }
}

.highlight {
  background: var(--color-highlight-section);
}

.undoRemoveSectionWrapper {
  align-items: center;
  background: rgba(256, 256, 256, 0.9);
  display: flex;
  height: 4rem;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
}

.accordionHeader {
  position: relative;
}

.btnUndoAction {
  color: var(--color-black);
  font-family: Lato, sans-serif;
  font-size: 1.6rem;

  &:hover {
    color: var(--color-black);
  }
}

.highlightBackground {
  background: var(--color-highlight-section);
  cursor: unset !important;
}

.linkDiscard {
  color: var(--color-gray-300);
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--color-gray-500);
  }
}

.linkSave {
  cursor: pointer;
  color: var(--color-black);
  font-weight: 400;
}

.emailHeaderAccordion {
  opacity: 0.5;
}

.headerTitleContainer {
  display: flex;
  align-items: center;
}

.editTitle {
  color: #656a76;
  font-size: 1.4rem;
  padding-top: 0.3rem;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: #285c97;
  }
}

.editTitleMargin {
  margin-left: -0.9rem;
}
