.searchByWrapper {
  position: relative;
  width: 100%;
}

.searchBySuggestionsContainer {
  background: var(--color-white);
  border-radius: 0.8rem;
  border: 0.1rem solid var(--color-gray-200);
  left: 0;
  margin-top: 0.5rem;
  max-height: 50rem;
  overflow: auto;
  position: absolute;
  top: 4.1rem;
  width: 100%;
  z-index: 9999;
  cursor: pointer;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: var(--color-gray-700);
      font-size: 1.6rem;
      font-weight: 400;
      padding: 1.1rem 1rem;
    }
  }
}

.activeOption {
  background: var(--color-gray-100);
}

.activeFiltersList {
  list-style: none;
  margin-top: 2rem;
  padding: 0;

  li {
    align-items: center;
    background: var(--color-gray-200);
    border-radius: 10rem;
    color: var(--color-gray-700);
    display: inline-block;
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 1.6rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    max-width: 100%;
    padding: 0rem 0.5rem 0 1rem;
    box-sizing: border-box;
    height: 3.2rem;

    border: 2px solid transparent;
    &:hover {
      border: 2px solid #c9cfe4;
    }
  }
}

.timesWrapper {
  color: var(--color-blue-strong);
  display: inline-block;
  height: 2.4rem;
  margin-left: 1rem;
  position: relative;
  width: 2.4rem;
}

.searchByHeadingContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

.searchByTitle {
  color: var(--color-light-gray);
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.clearSearchClass {
  color: var(--color-blue-primary);
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: underline;
}

.queryType {
  color: var(--color-black);
  white-space: break-spaces;
  text-transform: capitalize;
  font-size: 1.4rem;
}

.queryValue {
  color: var(--color-gray-400);
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 13rem;
}

.queryContainer {
  display: flex;
  max-width: 85%;
  padding-bottom: 0.2rem;
  align-items: center;
}
