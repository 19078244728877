.draggableWrapper {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.draggableBullets {
  display: flex;
  justify-content: space-between;
  margin-bottom: .3rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.bullet {
  background: var(--color-gray-300);
  border-radius: 10rem;
  height: .4rem;
  margin-right: .2rem;
  width: .4rem;

  &:last-child {
    margin-left: 0;
  }
}
