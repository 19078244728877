@import-normalize;

html,
body {
  font-family: 'Lato', sans-serif;
  font-size: 62.5%;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: inherit;
}

:root {
  /* Gray Scale */
  --color-gray: '#f2f5f8';
  --color-gray-100: #f2f5f6;
  --color-gray-100-opacity-40: rgba(242, 245, 246, 0.4);
  --color-gray-200: #eeeeee;
  --color-gray-250: #dbdbdb;
  --color-gray-300: #afb1b7;
  --color-gray-400: #757575;
  --color-gray-500: #848997;
  --color-gray-600: #505050;
  --color-gray-700: #333333;
  --color-gray-800: #272727;
  --color-gray-900: #101527;
  --color-gray-900-opacity-89: rgba(--color-gray-900, 0.89);
  --color-gray-navbar: #636a78;
  --color-gray-checkbox: #d3d3d3;
  --color-gray-footer-recipients: #646a77;
  --color-light-gray: #787e8c;
  --color-light-gray-secondary: #e9e9e9;

  /* Primary Color */
  --color-blue-main: #214b7d;
  --color-blue-light: #edf2fa;
  --color-blue-light-secondary: #c9cfe4;
  --color-blue-default: #2463af;
  --color-blue-dark: #004173;
  --color-blue-primary: #194590;
  --color-blue-link: #005da6;
  --color-blue-bright: #4e74da;
  --color-blue-strong: #101527;
  --color-blue: #194590;

  /* Accent Colors */
  --color-red-default: #f86071;
  --color-teal-default: #2fd9c0;
  --color-success-default: #44c2af;
  --color-yellow-default: #febf00;
  --color-purple-default: #886fb1;
  --color-orange-default: #f7944d;
  --color-green-default: #389e8f;

  --color-yellow-100: #fff6e6;

  /* White and Black */
  --color-white: #ffffff;
  --color-black: #000000;

  --color-white-opacity-1: #313644;

  --color-highlight-section: #ecf2fb;

  --color-card-list-hover: #edf2fa;

  --color-row-odd: rgba(234, 234, 234, 0.3);

  --color-card-list-hover: #edf2fa;

  /* Components */
  --color-chip-background: #9b9b9b;
  --color-sidebar-lines-separator: #30374a;
  --color-account-setup-input-background: #f2f5f8;
  --color-button-back-outline: #c9cfe4;

  /* Inputs */
  --color-form-field: #444444;
}

/* Override cropper styles */
.cropper-bg {
  background-image: unset !important;
  background: #b9bfd2;
}

.cropper-line {
  background-color: #101527;
}

.cropper-point {
  background: white;
  border: 0.1rem solid #101527;
  height: 7px !important;
  width: 7px !important;
}

.cropper-view-box {
  outline: 0.1rem solid #101527;
}

.cropper-modal {
  background: #c9cfe4;
}

.line-e,
.line-s,
.line-n,
.line-w {
  display: none;
}

.cropper-wrap-box,
.cropper-container,
.cropper-drag-box {
  border-radius: 0.8rem;
}

.markjs-highlight-text {
  background: #c9cfe4;
  padding: 0.3rem 0;
  border-radius: 0.8rem;
  color: var(--color-blue-primary);
}

.drawerOpen::-webkit-scrollbar {
  width: 0rem;
}

.str-chat * {
  font-family: 'Lato', sans-serif;
}
