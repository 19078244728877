.modalWrapper {
  max-width: 50rem;
  width: 100%;
}

.modalBody {
  margin: 3.3rem 3.2rem;
  padding: 0;
  .comment {
    font-size: 1.6rem;
    margin-top: 4rem;
    color: var(--color-gray-600);
  }

  > div:nth-child(3) {
    border: none;
    padding: 0;
    min-height: 0;
    max-height: 38rem;
    overflow: auto;
  }
}

.modalFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 0;
  margin-bottom: 1.1rem;
  padding-top: 3.2rem;
  button:first-child {
    margin-right: 1.5rem;
  }
}

.title {
  font-size: 1.8rem;
}

.modalSubtitle {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 2.4rem;
  max-width: 43.6rem;
}

.sendEmailTo {
  font-size: 1.4rem;
  font-weight: 700;
  svg {
    margin-right: 0.8rem;
  }
}
