.toggleLabel {
  cursor: pointer;
}

.toggleInput {
  clip: rect(0rem, 0rem, 0rem, 0rem);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0rem;
  position: absolute;
  white-space: nowrap;
  width: 0.1rem;

  & + div {
    background: #787e8c;
    border: none;
  }

  &:checked + div {
    background: var(--color-success-default);
    border: none;

    .toggleCircle {
      transform: translate(1.8rem, -50%);
    }
  }
}
.blue:checked + div {
  background: #194590;
}

.danger {
  &:checked + div {
    background: var(--color-red-default);
  }
}

.toggleWrapper {
  background: white;
  border-radius: 10rem;
  border: 0.1rem solid var(--color-gray-400);
  height: 2rem;
  position: relative;
  transition: background 0.3s ease-in-out;
  width: 3.6rem;
}

.toggleCircle {
  background: var(--color-white);
  border-radius: 10rem;
  height: 1.6rem;
  position: absolute;
  top: 50%;
  transform: translate(0.2rem, -50%);
  transition: transform 0.3s ease-in-out;
  width: 1.6rem;
}

.animationDisabled {
  .toggleWrapper {
    transition: none;
  }
  .toggleCircle {
    transition: none;
  }
}

.toggleContainer {
  display: flex;
  align-items: center;
}

.legend {
  font-weight: 400;
  font-size: 1.4rem;
  color: #4a4a68;
  margin-left: 1rem;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
