.container {
  display: flex;
  height: inherit;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.stepContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 4rem;

  span {
    width: 10rem;
  }

  &:focus {
    outline: none;
  }
}

.stepIconWrapper {
  align-items: center;
  background: var(--color-gray-200);
  border-radius: 10rem;
  display: flex;
  height: 3.6rem;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  transition: background 0.3s ease-in-out;
  width: 3.6rem;
  z-index: 1;

  > svg {
    color: rgba(120, 126, 140, 1);
    font-size: 1.6rem;
  }
}

.stepName {
  color: rgba(120, 126, 140, 1);
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 5.1rem;
  transition: color 0.3 ease-in-out;
  text-transform: uppercase;
}

.progressContainer {
  height: 4rem;
  position: absolute;
  width: 100%;
}

.progressWrapper {
  height: 4rem;
  position: relative;
  width: 100%;
}

.progressBar {
  border: 0.2rem dashed #e9e9e9;
  height: 0.1rem;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.activeStepClass {
  > div {
    background: white;
    border: 0.1rem solid #194590;

    > svg {
      color: rgba(25, 69, 144, 1);
    }
  }

  > span {
    color: rgba(25, 69, 144, 1);
    font-weight: bold;
  }
}

.content {
  display: none;
}
.activeContent {
  display: block;
}
