.inputContainer {
  display: flex;
  border-bottom: 0.1rem solid var(--color-gray-400);

  &:focus-within {
    border-bottom: 0.1rem solid var(--color-blue-default);

    svg {
      color: var(--color-blue-default);
    }
  }
}

.hideCaret {
  caret-color: transparent;
}

.pointer {
  cursor: pointer;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.inputField {
  color: var(--color-gray-600);
  font-size: 1.6rem;
  padding: 0.5rem 0.2rem;
  width: 100%;

  &::placeholder {
    color: var(--color-gray-300);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background: transparent;
  }
}

.fieldWithError {
  border-bottom: 0.1rem solid var(--color-red-default) !important;
}

.fieldErrorMessage {
  margin-top: 0.5rem;
  color: var(--color-red-default);
  font-size: 1.4rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputLabel {
  color: var(--color-blue-dark);
  font-size: 1.3rem;
}

.iconEnd {
  min-width: 1.8rem;
  height: 1.8rem;
  margin-top: 0.3rem;
  padding-bottom: 0.2rem;
  color: var(--color-gray-500);
}

.prefixContainer {
  font-size: 1.6rem;
  color: var(--color-gray-600);
  display: flex;
  align-items: center;
}

.optionalField {
  font-style: italic;
  color: #787e8c;
  font-size: 1.2rem;
  margin-left: 0.5rem;
}
