.mjml-entity-container {
  border-radius: 0.4rem;
  border: solid 0.1rem #979797;
}

.mjml-generator-container {
  padding: 0rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mjml-entity {
  display: flex;
  position: relative;
}

.mjml-entity.content-text {
  min-height: 13rem;
  border-radius: 0.4rem;
  border: 0.1rem dashed #979797;
}

.mjml-entity.content-image {
  border-radius: 0.4rem;
  border: 0.1rem dashed #979797;
  background: transparent;
}

.mjml-entry-wrapper {
  margin-bottom: 2rem;
}

.btn-wrapper {
  display: flex;
  justify-content: space-between;
  width: 30rem;
}

@media (max-width: 600px) {
  .btn-margins {
    margin: 0 1.5rem 1rem 0;
  }
}

@media (max-width: 375px) {
  .mjml-entity.content-image {
    max-width: 19.7rem;
  }
}

.draggable-entity {
  width: 2.5rem;
  height: auto;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
}

.draggable-icon {
  margin: auto 0.6rem;
}

.entities-area {
  min-height: 15rem;
}

.image-entity {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2rem 1.7rem;
}

.image-entity span {
  margin: auto 0;
  margin-right: 1rem;
}

.image-entity .icon {
  margin: auto 0;
  width: 3rem;
  height: 2rem;
}

.delete-entity {
  width: 2.5rem;
  height: auto;
  position: absolute;
  right: 1.3rem;
  top: 1rem;
  cursor: pointer;
  z-index: 9999;
}

.delete-entity svg {
  font-size: 2rem;
  border-radius: 50%;
  opacity: 0.61;
  color: var(--color-gray-300);
}

.image-entity svg {
  color: var(--color-gray-400);
  font-size: 2rem;
}

.image-box {
  width: 90%;
}

.image-box-small {
  width: 30rem;
}

div[id^='rich-text-editor-'] {
  width: 100%;
}

.mjml-image-container {
  width: 100%;
  margin: 1rem 1rem 0 1rem;
}
