@mixin avatar($size, $mx) {
  border-radius: 50%;
  text-align: center;
  width: $size;
  height: $size;
  border: 0.1rem solid #4a4a68;
  margin: 0 $mx;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  img {
    color: var(--color-white);
    position: relative;
    border-radius: 50%;
    font-size: $size * 0.5;
    line-height: $size * 0.5;
  }

  span {
    color: var(--color-white);
    position: relative;
    line-height: $size * 0.5;
    user-select: none;
  }
}

.avatarCircleXSM {
  @include avatar(2.5rem, 0rem);
  border: 1px solid var(--color-light-gray-secondary);
}

.avatarCircleSM {
  @include avatar(4.5rem, 2rem);
}

.avatarCircleLogs {
  @include avatar(3.2rem, 0);
  border: 1px solid var(--color-light-gray-secondary);
}

.avatarCircleXS {
  @include avatar(2rem, 0);
  border: 1px solid var(--color-light-gray-secondary);
}

.avatarCircleLarge {
  @include avatar(10.8rem, 0);
  border: 2px solid var(--color-light-gray-secondary);
}

.avatarCircleFundLogo {
  @include avatar(9rem, 0);
  border: 2px solid var(--color-light-gray-secondary);
  font-size: 3rem;
}

.avatarForUpdateFeed {
  @include avatar(3.4rem, 0);
  border: none;
  font-size: 1.6rem;
}

.avatarCircleHoldingLogo {
  @include avatar(3rem, 0);
  border: 1px solid var(--color-light-gray-secondary);
}

.avatarFundHoldingLogo {
  @include avatar(4.8rem, 0);
  border: 1px solid var(--color-light-gray-secondary);
  font-size: 1.5rem;
}

.avatarInitals {
  background-color: var(--color-light-gray);
}
.grayBorder {
  border: 2px solid var(--color-light-gray-secondary);
}

.initials_avatarCircleXS {
  font-size: 0.8rem;
}

.initials_avatarCircleXSM {
  font-size: 1rem;
}

.initials_avatarCircleSM {
  font-size: 2rem;
}

.initials_avatarCircleLogs {
  font-size: 1.4rem;
}

.initials_avatarCircleLarge {
  font-size: 5.5rem;
}
