.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  max-width: 50rem;
  margin: auto;

  svg {
    fill: var(--color-black);
    margin-bottom: 3rem;
    width: 20rem;
  }

  h1 {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }

  details {
    margin-top: 3rem;
    font-size: 1.5rem;
    max-width: 50rem;
    align-self: flex-start;
    font-weight: 400;
    cursor: pointer;

    summary {
      font-weight: 600;
      padding: 0.5rem;
    }
  }
}

.errorContainer {
  max-height: 14rem;
  overflow: auto;
  padding-top: 0.5rem;
}

.reportFeedback {
  background: var(--color-blue-dark);
  color: var(--color-white);
  padding: 1rem 2rem;
  font-size: 1.4rem;
}

.reload {
  border: 0.1rem solid var(--color-blue-dark);
  color: var(--color-blue-dark);
  padding: 1rem 2rem;
  font-size: 1.4rem;
}

.btnContainer {
  margin-top: 3rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
