.emailPreview {
  padding: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  background body {
    background-color: var(--color-white);
  }
}

.rightColumn {
  @media (max-width: 1040px) {
    width: 100%;
    & > * {
      margin-bottom: 2rem;
    }
  }
}

.hidden {
  display: none;
}

.updateContentWrapper {
  background: var(--color-white);
  border-radius: 0.8rem;
  padding: 2.9rem 3rem;
}

.date {
  font-size: 1.4rem;
}

.dateExpanded {
  color: #787e8c;
}

.emailContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.emailColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4a4a68;
  font-weight: bold;
  font-size: 1.4rem;
}

.avatar {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.17;
  color: white;
  text-transform: uppercase;
  width: 2.1rem;
  height: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6.82rem;
  border: 1px solid #194590;
  background-color: #194590;
  margin-right: 0.7rem;
}

.emailPreviewWrapper {
  border: 0.1rem solid #e9e9e9;
  border-radius: 0.8rem;
  width: 100%;
  overflow: hidden;
  margin-top: 2rem;
}
.editEmailPreviewWrapper {
  @extend .emailPreviewWrapper;
  margin-top: 0rem;
}

.statusText {
  color: #4a4a68;
  font-style: italic;
  font-size: 1.4rem;
}
