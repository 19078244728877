.sendTestWrapper {
  margin-right: 2.4rem;

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    place-content: flex-end;
  }

  button {
    svg {
      margin-right: 1rem;
    }
  }

  h2 {
    padding: 1rem 1.7rem;
  }

  div[class*='Modal_modalWrapper'] {
    min-width: 61.3rem;

    @media (max-width: 643px) {
      min-width: 90vw;
    }
  }
}

.modalBody {
  padding: 0 1.7rem;

  .comment {
    font-size: 1.6rem;
    margin-top: 4rem;
    color: var(--color-gray-600);
  }

  div[class*='Input_inputContainer'] {
    margin-top: 0.5rem;
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button:nth-child(2) {
    color: var(--color-white);
    border: none;
    background-color: var(--color-blue-dark);
  }
}

.userEmailChip {
  margin-top: 1rem;
  width: fit-content;
  cursor: pointer;

  > p {
    margin-right: 1rem;
  }
}

button.btnTestEmail {
  border-color: transparent;

  svg[class*='angleLeft'] {
    display: none;
  }
}

.emailListInput {
  margin-top: 1rem;
}

.tagsInput {
  border-radius: 10rem;
  padding: 0.5rem;

  input {
    margin-right: 1rem;
    margin-left: 0.5rem;
    flex-grow: 2;
  }
}

.legend {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 1rem;
  > span {
    font-weight: 500;
    color: var(--color-black);
    font-size: 1.4rem;
    margin-bottom: 1.3rem;
    margin-left: 1.8rem;
  }

  > button {
    font-size: 1.4rem;
    color: var(--color-blue-dark);
    text-decoration: underline;
  }
}

.noBorderRadius {
  border-radius: 0.2rem;
}
